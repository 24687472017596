<template>
  <div v-if="settings">
    <v-card class="import-settings">
      <v-card-title>
        <span>{{ $t('t.Import') }}</span>
        <v-btn
          class="ml-4"
          :disabled="!isDirty"
          fab
          x-small
          color="success"
          @click.stop="save()"
        >
          <v-icon ref="check">{{ $icon('i.Checked') }}</v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          :disabled="!isDirty"
          fab
          x-small
          color="warning"
          @click.stop="load()"
        >
          <v-icon>{{ $icon('i.Undo') }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-subheader>
          <span class="insurance-provider-title">{{ $t('t.CsvFile') }}&nbsp;</span>
        </v-subheader>
      </v-card-text>
      <v-card-text>
        <v-subheader>
          <span class="insurance-provider-title">{{ $t('t.Hierarchy') }}&nbsp;</span>
        </v-subheader>
      </v-card-text>
      <v-card-text>
        <v-subheader>
          <span class="insurance-provider-title">{{ $t('t.Contacts') }}&nbsp;</span>
        </v-subheader>
      </v-card-text>
      <v-card-text>
        <v-subheader>
          <span class="insurance-provider-title">{{ $t('t.Currencies') }}&nbsp;</span>
        </v-subheader>
      </v-card-text>
      <v-card-text>
        <v-subheader>
          <span class="insurance-provider-title">{{ $t('t.Fees') }}&nbsp;</span>
        </v-subheader>
      </v-card-text>
      <v-card-text>
        <v-subheader>
          <span class="insurance-provider-title">{{ $t('t.DefaultDateFormat') }}&nbsp;</span>
        </v-subheader>
      </v-card-text>
      <v-card-text>
        <v-subheader>
          <span class="insurance-provider-title">{{ $t('t.ImportPlanner') }}&nbsp;</span>
        </v-subheader>
      </v-card-text>
      <v-card-text>
        <v-subheader>
          <span class="insurance-provider-title">{{ $t('t.DataSourceConfiguration') }}&nbsp;</span>
        </v-subheader>
      </v-card-text>
      <!-- <v-card-text>
        <v-subheader>
          <span class="insurance-provider-title">{{ $t('t.Groupama') }}&nbsp;</span>
          <v-switch
            dense
            v-model="settings.groupamaIsActive"
          />
        </v-subheader>
        <v-divider></v-divider>
        <ul class="responsive-grid">
          <v-text-field
            v-model="settings.groupamaUrl"
            :disabled="!this.settings.groupamaIsActive"
            :label="$t('t.ApplicationBaseURL')"
            type="url"
          ></v-text-field>
          <date-picker-menu
            v-model="settings.groupamaLastCall"
            :disabled="!this.settings.groupamaIsActive"
            :label="$t('t.LastCall')"
          />
          <v-text-field
            v-model="settings.groupamaLoginApi"
            :disabled="!this.settings.groupamaIsActive"
            :label="$t('t.IdentifiantAPI')"
          ></v-text-field>
          <v-text-field
            v-model="settings.groupamaPasswordApi"
            :append-icon="showApiPassword ? $icon('i.Eye') : $icon('i.EyeOff')"
            :type="showApiPassword ? 'text' : 'password'"
            @click:append="showApiPassword = !showApiPassword"
            :disabled="!this.settings.groupamaIsActive"
            :label="$t('t.PasswordAPI')"
          >
          </v-text-field>
        </ul>
      </v-card-text> -->
    </v-card>
  </div>
</template>
<script>
export default {
  mounted () {
    this.load()
  },
  activated () {
    this.load()
  },
  components: {
    // DatePickerMenu: () => import('@/components/date-picker-menu')
  },
  computed: {
    isDirty () {
      return !(JSON.stringify(this.settings) === JSON.stringify(this.initialSettings))
    }
  },
  data () {
    return {
      showApiPassword: false,
      initialSettings: null,
      settings: null
    }
  },
  methods: {
    async load () {
      const r = await this.$http().get('/core/import/settings')

      this.settings = r?.data
      this.initialSettings = Object.assign({}, r?.data)
    },
    async save () {
      const settingsCopy = Object.assign({}, this.settings)

      await this.$http().post('/core/import/settings', settingsCopy)
        .then(() => {
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SaveConfirmation', { title: this.$t('t.CreditInsurance') }))
          this.load()
        })
        .catch(e => this.$store.dispatch('showErrorSnackbar', e.response?.data?.message))
    }
    // verifyUrl (val) {
    //  return /^[a-z]+:\/\/[a-z0-9_-]+(?:\.[a-z0-9_-]+)*(?::[0-9]+)?(?:\/.*)?$/mi.test(val)
    // }
  }
}
</script>

<style lang="stylus">
.responsive-grid
  display grid
  grid-template-columns repeat(auto-fill, minmax(15rem, auto))
  column-gap 1em

.insurance-provider-title
  padding 1em
</style>
